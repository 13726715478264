import { FunctionComponent, useRef, useState } from 'react';
import { useTranslate } from '@xFrame4/components/Hooks';
import XForm from '@xFrame4/components/common/XForm';
import FieldEditor, { FieldEditorLabelPosition, FieldEditorType } from '@xFrame4/components/common/FieldEditor';
import Message from '@business/contact/Message';
import BaseButton from '@components/common/BaseButton';
import BaseAlert from '@components/common/BaseAlert';

export interface ContactFormProps
{

}

const ContactForm: FunctionComponent<ContactFormProps> = (props) =>
{
    const t = useTranslate();
    const formRef = useRef(null);
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [isWorking, setIsWorking] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);

    /** Submit the form */
    const submitForm = async () =>
    {
        if (formRef.current != null) (formRef.current as any).submit();
    }

    /** Extra validation */
    const validate = () =>
    {
        if (!email)
        {
            setErrorMessage(t('EMAIL_REQUIRED'));
            return false;
        }

        if (!name)
        {
            setErrorMessage(t('NAME_REQUIRED'));
            return false;
        }

        if (!message)
        {
            setErrorMessage(t('MESSAGE_REQUIRED'));
            return false;
        }

        return true;
    }

    /** Handle submit */
    const handleSubmit = async () =>
    {
        setIsWorking(true);

        let contactMessage = new Message();
        contactMessage.name = name;
        contactMessage.email = email;
        contactMessage.phone = phone;
        contactMessage.message = message;
        let success = await contactMessage.save();

        if (success)
        {
            setName('');
            setEmail('');
            setPhone('');
            setMessage('');
            setSuccessMessage(t('MESSAGE_SENT'));
            setErrorMessage(undefined);
        }
        else
        {
            setErrorMessage(t('MESSAGE_NOT_SENT'));
        }

        setIsWorking(false);
    }

    /** Render */
    return (
        <div className="contact-form">
            <div className="row justify-content-center">
                <div className="col-lg-10 col-xl-8">
                    <div className="text-center mb-5">
                        <div className="font-size-2 font-weight-500 mb-3">{t('CONTACT_PAGE_TITLE')}</div>
                        <div className="font-size-2">{t('CONTACT_PAGE_SUBTITLE')}</div>
                    </div>

                    <XForm
                        ref={formRef}
                        className=""
                        onValidate={() => validate()}
                        onSubmit={() => handleSubmit()}
                    >
                        <div>
                            <div className="row">
                                <div className="col-lg-4">
                                    <FieldEditor
                                        type={FieldEditorType.Email}
                                        field="email"
                                        label={t('EMAIL')}
                                        placeholder={t('EMAIL')}
                                        labelPosition={FieldEditorLabelPosition.Hidden}
                                        isRequired={true}
                                        isEnabled={!isWorking}
                                        className="mb-3"
                                        value={email}
                                        onValueChanged={(field, value) => setEmail(value)}
                                    />
                                </div>

                                <div className="col-lg-4">
                                    <FieldEditor
                                        type={FieldEditorType.Text}
                                        field="name"
                                        label={t('NAME')}
                                        placeholder={t('NAME')}
                                        labelPosition={FieldEditorLabelPosition.Hidden}
                                        isRequired={true}
                                        isEnabled={!isWorking}
                                        className="mb-3"
                                        value={name}
                                        onValueChanged={(field, value) => setName(value)}
                                    />
                                </div>

                                <div className="col-lg-4">
                                    <FieldEditor
                                        type={FieldEditorType.Text}
                                        field="phone"
                                        label={t('PHONE')}
                                        placeholder={t('PHONE')}
                                        labelPosition={FieldEditorLabelPosition.Hidden}
                                        isEnabled={!isWorking}
                                        className="mb-3"
                                        value={phone}
                                        onValueChanged={(field, value) => setPhone(value)}
                                    />
                                </div>
                            </div>

                            <div className="">
                                <FieldEditor
                                    type={FieldEditorType.Textarea}
                                    field="message"
                                    label={t('MESSAGE')}
                                    placeholder={t('MESSAGE')}
                                    labelPosition={FieldEditorLabelPosition.Hidden}
                                    textareaRows={12}
                                    isRequired={true}
                                    isEnabled={!isWorking}
                                    className="mb-3"
                                    value={message}
                                    onValueChanged={(field, value) => setMessage(value)}
                                />
                            </div>

                            <div className="mb-5">
                                <BaseButton
                                    text={t('SUBMIT')}
                                    variant="primary"
                                    className="w-100 py-3"
                                    disabled={isWorking}
                                    onClick={() => submitForm()}
                                />
                            </div>

                            <div className="alerts">
                                {errorMessage && <BaseAlert message={errorMessage} />}
                                {successMessage && <BaseAlert message={successMessage} variant="success" />}
                            </div>
                        </div>
                    </XForm>
                </div>
            </div>
        </div>
    );
}

export default ContactForm;