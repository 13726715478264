import { FunctionComponent } from 'react';
import { useTranslate } from '@xFrame4/components/Hooks';

export interface HeaderProps
{

}

const Header: FunctionComponent<HeaderProps> = (props) =>
{
    const t = useTranslate();

    /** Render */
    return (
        <div className="header py-5 d-flex justify-content-center">
            <div className="section-title">
                {t('APP_TITLE')}
            </div>
        </div>
    );
}

export default Header;