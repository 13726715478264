import { FunctionComponent } from 'react';
import { Alert } from 'react-bootstrap';

export interface BaseAlertProps
{
    message?: string;
    variant?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark';
    className?: string;
}

const BaseAlert: FunctionComponent<BaseAlertProps> = (props) =>
{
    /** Render */
    return (
        <Alert 
            variant={props.variant ?? 'danger'}
            className={'base-alert ' + (props.className ?? '')}
            show={props.message !== undefined}
        >
            {props.message}
        </Alert>
    );
}

export default BaseAlert;