import { FunctionComponent } from 'react';
import { useTranslate } from '@xFrame4/components/Hooks';

export interface FooterProps
{

}

const Footer: FunctionComponent<FooterProps> = (props) =>
{
    const t = useTranslate();
    
    /** Render */
    return (
        <div className="footer pb-env">
            <div className="py-5 d-flex justify-content-center small">
                © 2024, {t('APP_TITLE')}
            </div>
        </div>
    );
}

export default Footer;