import { FunctionComponent, forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useSetStateFromProp } from '../Hooks';

interface XFormProps
{
    className?: string;
    children?: any;
    /** Validates the data before submitting. */
    onValidate?: () => boolean;
    /** Handle the submit process. */
    onSubmit: () => Promise<void | boolean>;
}

/**
 * Component: basic form for handling form submit.
 * Use forwardRef and useImperativeHandle to expose the submit method.
 * https://legacy.reactjs.org/docs/forwarding-refs.html
 * https://react.dev/reference/react/useImperativeHandle
 */
const XForm = forwardRef((props: XFormProps, ref) =>
{
    const formRef = useRef<HTMLFormElement>(null);
    const [validated, setValidated] = useState<boolean>();

    /** Handle form submit */
    const submit = async () =>
    {
        if (formRef.current == null) return;

        // validate
        if (formRef.current.checkValidity() === true) // standard validation
        {
            let isValid = true;

            // additional custom validation
            if (props.onValidate !== undefined) isValid = props.onValidate(); 

            // set validated
            setValidated(true);

            // if the form is valid, process the submit
            if (isValid)
            {
                if (props.onSubmit !== undefined) await props.onSubmit();
                setValidated(false); // reset validated
            }
        }
        else
        {
            setValidated(true);
        }
    };

    /** Reset the form */
    const reset = () =>
    {
        setValidated(false);
        // formRef.current?.reset();
    };

    useImperativeHandle(ref, () => ({
        submit: () => submit(),
        reset: () => reset()
    }));

    /** Handle the enter key */
    const handleKeyDown = (event: React.KeyboardEvent) => 
    {
        if (event.key === 'Enter' && !event.shiftKey) 
        {
            event.preventDefault();
            submit();
        }
    };
    
    /** Render */
    return (
        <Form
            ref={formRef}
            noValidate
            validated={validated}
            onSubmit={() => false} // prevent default submit
            onKeyDown={(event) => handleKeyDown(event)}
            className={'x-form ' + (props.className ?? '')}
        >
            {props.children}
        </Form>
    );
});

export default XForm;