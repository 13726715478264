import BusinessEntity, { EntityManager, EntityManagerParameters } from '@xFrame4/business/base/BusinessEntity';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';
import { ManyToManyCollection } from '@xFrame4/business/base/ManyToManyCollection';
import { BusinessEntityWithTranslation, BusinessEntityTranslation, EntityWithTranslationManager, EntityTranslationManager, EntityWithTranslationManagerParameters } from '@xFrame4/business/base/BusinessEntityWithTranslation';



export class MessageGeneratedEntityManager<B extends MessageGenerated> extends EntityManager<B>
{
    constructor(parameters: EntityManagerParameters<B>)
    {
        super({
            name: parameters.name ?? 'Message',
            createEntity: parameters.createEntity,
            fields: [
				{ name: 'name', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
				{ name: 'email', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
				{ name: 'phone', type: BusinessEntityFieldType.VarChar },
				{ name: 'message', type: BusinessEntityFieldType.Text, isRequiredInput: true },
				{ name: 'dateSent', type: BusinessEntityFieldType.DateTime, isInput: false },
                ... parameters.fields ?? [],
            ],
            graphQlQueryAlias: parameters.graphQlQueryAlias ?? 'messages',
        });
    }

}
export class MessageGenerated extends BusinessEntity
{
	name!: string;
	email!: string;
	phone: string = '';
	message!: string;
	dateSent!: string;

}

export default MessageGenerated;