import MessageGenerated, { MessageGeneratedEntityManager } from './generated/Message.generated';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';

export class MessageEntityManager extends MessageGeneratedEntityManager<Message>
{
    constructor()
    {
        super({
            createEntity: () => new Message(),
        });
    }
}

export class Message extends MessageGenerated
{
    static _manager: MessageEntityManager;
    static get manager(): MessageEntityManager
    {
        if (!this._manager) this._manager = new MessageEntityManager();
        return this._manager;
    }
}

export default Message;