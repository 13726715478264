import { FunctionComponent } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

interface BaseButtonProps
{
    text?: string;
    variant?: string;
    outline?: boolean;
    className?: string;
    width?: number;
    /** If provided, the button will be wrapped in a Next.js Link */
    href?: string;
    disabled?: boolean;
    children?: any;
    onClick?: () => void;
}

const BaseButton: FunctionComponent<BaseButtonProps> = (props) =>
{
    // variant
    let variant = props.variant ?? '';
    if (props.outline && variant !== '')
    {
        variant = `outline-${variant}`;
    }

    // width
    let width = props.width !== undefined ? `w-${props.width}` : '';

    // content
    let cmpContent = props.children !== undefined ? props.children : props.text;
    
    // button
    let cmpButton =
        <Button
            variant={variant}
            className={`base-button ${width} ${props.className}`}
            disabled={props.disabled}
            onClick={props.onClick}
        >
            {cmpContent}
        </Button>
    
    // wrap in link if href is provided
    if (props.href !== undefined)
    {
        cmpButton =
            <Link
                to={props.href}
            >
                {cmpButton}
            </Link>
    }

    /** Render */
    return cmpButton;
}

export default BaseButton;